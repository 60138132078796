import axios from 'axios'
import store from '@/store'
import * as config from '../../../config.js'
/**
 * init class API
 * @param opt
 * @returns {AdminApi}
 * @constructor
 */

function AdminApi (opt) {
  if (!(this instanceof AdminApi)) {
    return new AdminApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
  this.api.interceptors.request.use(request => {
    store.dispatch('SetIsApiRunning', true)
    return request
  })
  this.api.interceptors.response.use(response => {
    store.dispatch('SetIsApiRunning', false)
    return response
  },
  error => {
    store.dispatch('SetIsApiRunning', false)
    return error
  })
}
/**
 * init class API
 * @param opt
 * @returns {AdminApi}
 * @constructor
 */

AdminApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}
AdminApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  var param = ''
  if (data !== '') {
    param = '' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestFileDataGet = function (url, contentType, data, callback) {
  var headers = {
    responseType: 'arraybuffer'
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

AdminApi.prototype.sourceDisplayOrderUpdate = async function (data, callback) {
  const requestUrl = 'api/v1/admin/source/reorder'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.DashboardOverview = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/overview'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getOrganizationList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getOrganizationDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getUserList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/userList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getCustomerList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/customerList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getLeadListing = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/leadList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getJobList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/jobs'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getInvoiceList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/invoices'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getInvoiceStatus = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/invoiceStatus'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getPaymntList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/payments'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getEntityList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/entityList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getEmailTemplateList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailTemplate/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getEmailTemplateDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailTemplate/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getEmailMergeFieldList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailMergeFiled'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.updateEmailTemplate = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailTemplate/update'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.getAppSettingList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/appSetting/getAppSettingDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.UpdateAppSettingList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/appSetting/updateAppSetting'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetSupportList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/support/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetSupportDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/support/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetFeedbackList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/feedback/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetFeedbackDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/feedback/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetFeaturesList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetCurrentyList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/currencyList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetPlanList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/plan/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SavePlanApi = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/plan/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetPlanDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/plan/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ActiveDeactivePlan = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/plan/activateAndDeactivate'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.DeletePlanApi = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/appFeature/plan/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateEmailConfiguration = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailConfiguration/update'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetDeviceList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/user/deviceLog'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateExpiryDate = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/freePlan/expiryChange'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.CreateTestJobs = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/job/createTestJobs'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ExtendExpiryDays = async function (data, callback) {
  const requestUrl = 'api/v1/subscription/extendExpireDaysWeb'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetActivityEntityList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/entityList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetActivityOrgSearch = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/search'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetSpecificUserOrg = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/users'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetAllOrgActivityList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/activityLog'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetAllAttachmentList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/allAttachmentList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.DeleteAttachment = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/deleteAttachment'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetAttachmentType = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/attachmentTypeList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getCustomerDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/customerDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getLeadDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/leadDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetInvoiceDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/invoiceDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetPaymentDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/paymentDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetUserDetailLogin = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/user/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateLoginUserDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/user/update'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SendOtpInEmail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/sendOtpEmail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ResendOTP = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/resendOTP'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.VerifiOTP = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/verifyOTP'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateLoginPassword = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/updateLoginPassword'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.JobDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/jobDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.JobItemList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/jobItemList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.JobVisitsList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/jobVisitsList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getPaymentList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/payments'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getQuotationStatusList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/quoteStatus'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getQuotationList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/quotes'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.requestlist = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/requestList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetTaskListingForList = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/taskList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetQuoteDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/quoteDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getNotesLIst = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/notes'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getOrgDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/addressDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getVisitDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/visitDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getLogedTIme = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/visitLoggedTimeDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getJobLocation = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/jobVisitLocation'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetTaskDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/taskDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetRequestDetail = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/organization/requestDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SendOTPToOwner = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/sendOtpToAd'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ReSendOTPToOwner = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/resendAdOTP'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.VarifiedOTPToOwner = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/AdOtpVerify'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SendOTPToSAdmin = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/sendOtpToSAd'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ReSendOTPToSAdmin = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/resendSAdOTP'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.VarifiedOTPToSAdmin = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/auth/SAdOtpVerify'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}



// GET


AdminApi.prototype.GetEmailConfDetail  = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailConfiguration/detail'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

export default AdminApi
