import Api from './API'
import {getCookies} from '@/utils/cookies'
// import { get } from 'http'
// const crypto = require('crypto')

function AdminModel () {
  if (!(this instanceof AdminModel)) {
    return new AdminModel()
  }
}

/* ***** *********** ************* **************** ******************* ****************** ****
*
*                                             HRM material Api
*
***** *********** ************* **************** ******************* ****************** **** */
/** ***********************HRM SETTINGS START HERE*********************************/

/** ***********************CONTACT TYPE******************************** */
AdminModel.prototype.sourceDisplayOrderUpdate = async function (
  sourceDetail,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('sourceDetail', JSON.stringify(sourceDetail))
  Api.sourceDisplayOrderUpdate(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.DashboardOverview = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.DashboardOverview(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getOrganizationList = async function (
  isActive,
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("isActive", isActive);
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  Api.getOrganizationList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getUserList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  isActive,
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  data.append("isActive", isActive);
  data.append("organizationId", organizationId);
  Api.getUserList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getCustomerList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  data.append("organizationId", organizationId);
  Api.getCustomerList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getLeadListing = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchValue", searchValue);
  data.append("sortColumnName", sortColumnName);
  data.append("sortColumnOrder", sortColumnOrder);
  data.append("organizationId", organizationId);
  Api.getLeadListing(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getJobList = async function (
  start,
  length,
  searchValue,
  statusIdList,
  sortColumnName,
  sortColumnOrder,
  from,
  to,
  isNone,
  organizationId,
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchValue);
  data.append("statusIdList", statusIdList);
  data.append("shortBy", sortColumnName);
  data.append("sortOrder", sortColumnOrder);
  data.append("from", from);
  data.append("to", to);
  data.append("isNone", isNone);
  data.append("organizationId", organizationId);
  data.append("customerId", customerId);
  Api.getJobList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getInvoiceList = async function (
  start,
  length,
  searchValue,
  statusIdList,
  sortColumnName,
  sortColumnOrder,
  from,
  to,
  organizationId,
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchValue);
  data.append("statusIdList", statusIdList);
  data.append("shortBy", sortColumnName);
  data.append("sortOrder", sortColumnOrder);
  data.append("from", from);
  data.append("to", to);
  data.append("organizationId", organizationId);
  data.append("customerId", customerId);
  Api.getInvoiceList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getPaymntList = async function (
  start,
  length,
  searchValue,
  sortColumnName,
  sortColumnOrder,
  customerId,
  invoiceId,
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchValue);
  data.append("shortBy", sortColumnName);
  data.append("sortOrder", sortColumnOrder);
  data.append("customerId", customerId);
  data.append("invoiceId", invoiceId);
  data.append("organizationId", organizationId);
  Api.getPaymntList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getOrganizationDetail = async function (
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("organizationId", organizationId);
  Api.getOrganizationDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getInvoiceStatus = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.getInvoiceStatus(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getEntityList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.getEntityList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getEmailTemplateList = async function (
  entityId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("entityId", entityId);
  Api.getEmailTemplateList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getEmailTemplateDetail = async function (
  emailTemplateId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("emailTemplateId", emailTemplateId);
  Api.getEmailTemplateDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getEmailMergeFieldList = async function (
  entitySlug,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("entitySlug", entitySlug);
  Api.getEmailMergeFieldList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.updateEmailTemplate = async function (
  saveEmailTemplate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('saveEmailTemplate', JSON.stringify(saveEmailTemplate))
  Api.updateEmailTemplate(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getAppSettingList = async function (
  platForm,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('platForm', platForm)
  Api.getAppSettingList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.UpdateAppSettingList = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.UpdateAppSettingList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetSupportList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  issueClosed,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchVal);
  data.append("shortBy", shortBy);
  data.append("sortOrder", sortOrder);
  data.append("issueClosed", issueClosed);
  Api.GetSupportList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetSupportDetail = async function (
  supportId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("supportId", supportId);
  Api.GetSupportDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetFeedbackList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchVal);
  data.append("shortBy", shortBy);
  data.append("sortOrder", sortOrder);
  Api.GetFeedbackList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetFeedbackDetail = async function (
  feedbackId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("feedbackId", feedbackId);
  Api.GetFeedbackDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetFeaturesList = async function (
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("isActive", isActive);
  Api.GetFeaturesList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetCurrentyList = async function (
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("isActive", isActive);
  Api.GetCurrentyList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetPlanList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  isFree,
  isActive,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchVal);
  data.append("shortBy", shortBy);
  data.append("sortOrder", sortOrder);
  data.append("isFree", isFree);
  data.append("isActive", isActive);
  Api.GetPlanList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.SavePlanApi = async function (
  planData,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('planData', JSON.stringify(planData))
  Api.SavePlanApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetPlanDetail = async function (
  planId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("planId", planId);
  Api.GetPlanDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.DeletePlanApi = async function (
  planId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("planId", planId);
  Api.DeletePlanApi(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ActiveDeactivePlan = async function (
  planId,
  requestType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("planId", planId);
  data.append("requestType", requestType);
  Api.ActiveDeactivePlan(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.SendOtpInEmail = async function (
  sendTo,
  requestType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append("sendTo", sendTo);
  data.append("requestType", requestType);
  Api.SendOtpInEmail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ResendOTP = async function (
  regToken,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken);
  Api.ResendOTP(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.VerifiOTP = async function (
  regToken,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken);
  data.append('otp', otp);
  Api.VerifiOTP(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.UpdateLoginPassword = async function (
  regToken,
  password,
  currentPass,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('regToken', regToken);
  data.append('password', password);
  data.append('currentPass', currentPass);
  Api.UpdateLoginPassword(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.UpdateEmailConfiguration = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.UpdateEmailConfiguration(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetDeviceList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  organizationDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchVal);
  data.append("shortBy", shortBy);
  data.append("sortOrder", sortOrder);
  data.append("organizationDetailId", organizationDetailId);
  Api.GetDeviceList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.UpdateExpiryDate = async function (
  subscriptionId,
  endDate,
  notes,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("subscriptionId", subscriptionId);
  data.append("endDate", endDate);
  data.append("notes", notes);
  Api.UpdateExpiryDate(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ExtendExpiryDays = async function (
  organizationId,
  extendValue,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append("organizationId", organizationId);
  data.append("extendValue", extendValue);
  Api.ExtendExpiryDays(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetActivityEntityList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.GetActivityEntityList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetActivityOrgSearch = async function (
  start,
  length,
  searchVal,
  orgId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('orgId', orgId)
  Api.GetActivityOrgSearch(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetSpecificUserOrg = async function (
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId)
  Api.GetSpecificUserOrg(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetAllOrgActivityList = async function (
  start,
  length,
  searchVal,
  entityIdList,
  userDetailIdList,
  organizationId,
  fromDate,
  toDate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('start', start)
  data.append('length', length)
  data.append('searchVal', searchVal)
  data.append('entityIdList', entityIdList)
  data.append('userDetailIdList', userDetailIdList)
  data.append('organizationId', organizationId)
  data.append('fromDate', fromDate)
  data.append('toDate', toDate)
  Api.GetAllOrgActivityList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetAllAttachmentList = async function (
  organizationId,
  start,
  length,
  shortBy,
  sortOrder,
  attachmentFilterList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId)
  data.append('start', start)
  data.append('length', length)
  data.append('shortBy', shortBy)
  data.append('sortOrder', sortOrder)
  data.append('attachmentFilterList', attachmentFilterList)
  Api.GetAllAttachmentList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.DeleteAttachment = async function (
  deletedAttachment,
  excludeDeleteAttachment,
  isDeleteAll,
  requestType,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('deletedAttachment', deletedAttachment)
  data.append('excludeDeleteAttachment', excludeDeleteAttachment)
  data.append('isDeleteAll', isDeleteAll)
  data.append('requestType', requestType)
  Api.DeleteAttachment(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetAttachmentType = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.GetAttachmentType(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getCustomerDetail = async function (
  customerId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('customerId', customerId)
  Api.getCustomerDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getLeadDetail = async function (
  leadId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('leadId', leadId)
  Api.getLeadDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetInvoiceDetail = async function (
  invoiceId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('invoiceId', invoiceId)
  Api.GetInvoiceDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetPaymentDetail = async function (
  paymentId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('paymentId', paymentId)
  Api.GetPaymentDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetUserDetailLogin = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.GetUserDetailLogin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.UpdateLoginUserDetail = async function (
  reqObj,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('reqObj', JSON.stringify(reqObj))
  Api.UpdateLoginUserDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.JobDetail = async function (
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('jobId', jobId);
  Api.JobDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.JobItemList = async function (
  visitId,
  jobId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('jobId', jobId);
  data.append('visitId', visitId);
  Api.JobItemList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.JobVisitsList = async function (
  orgId,
  jobId,
  filterType,
  start,
  length,
  userDetailIdList,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('orgId', orgId);
  data.append('jobId', jobId);
  data.append('filterType', filterType);
  data.append('start', start);
  data.append('length', length);
  data.append('userDetailIdList', userDetailIdList);
  Api.JobVisitsList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getPaymentList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  customerId,
  invoiceId,
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('start', start);
  data.append('length', length);
  data.append('searchVal', searchVal);
  data.append('shortBy', shortBy);
  data.append('sortOrder', sortOrder);
  data.append('customerId', customerId);
  data.append('invoiceId', invoiceId);
  data.append('organizationId', organizationId);
  Api.getPaymentList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getQuotationList = async function (
  organizationId,
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  requestType,
  statusIdList,
  customerId,
  from,
  to,
  expiryNext7Days,
  quoteApproveOnThisWeek,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('start', start);
  data.append('length', length);
  data.append('searchVal', searchVal);
  data.append('shortBy', shortBy);
  data.append('sortOrder', sortOrder);
  data.append('requestType', requestType);
  data.append('statusIdList', statusIdList);
  data.append('customerId', customerId);
  data.append('from', from);
  data.append('to', to);
  data.append('expiryNext7Days', expiryNext7Days);
  data.append('quoteApproveOnThisWeek', quoteApproveOnThisWeek);
  Api.getQuotationList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.requestlist = async function (
  organizationId,
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  filterType,
  customerId,
  statusIdList,
  userDetailIdList,
  from,
  to,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('start', start);
  data.append('length', length);
  data.append('searchVal', searchVal);
  data.append('shortBy', shortBy);
  data.append('sortOrder', sortOrder);
  data.append('filterType', filterType);
  data.append('customerId', customerId);
  data.append('statusIdList', statusIdList);
  data.append('userDetailIdList', userDetailIdList);
  data.append('from', from);
  data.append('to', to);
  Api.requestlist(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetTaskListingForList = async function (
  organizationId,
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  customerId,
  userDetailIdList,
  fromDate,
  toDate,
  isCompleted,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('start', start);
  data.append('length', length);
  data.append('searchVal', searchVal);
  data.append('shortBy', shortBy);
  data.append('sortOrder', sortOrder);
  data.append('customerId', customerId);
  data.append('userDetailIdList', userDetailIdList);
  data.append('fromDate', fromDate);
  data.append('toDate', toDate);
  data.append('isCompleted', isCompleted);
  Api.GetTaskListingForList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getQuotationStatusList = async function (
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  Api.getQuotationStatusList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetQuoteDetail = async function (
  organizationId,
  quotationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('quotationId', quotationId);
  Api.GetQuoteDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getNotesLIst = async function (
  organizationId,
  requestType,
  requestId,
  start,
  length,
  noteMasterTypeId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('requestType', requestType);
  data.append('requestId', requestId);
  data.append('start', start);
  data.append('length', length);
  data.append('noteMasterTypeId', noteMasterTypeId);
  Api.getNotesLIst(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getOrgDetail = async function (
  organizationId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  Api.getOrgDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getVisitDetail = async function (
  organizationId,
  visitId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('visitId', visitId);
  Api.getVisitDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getJobLocation = async function (
  organizationId,
  visitId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('visitId', visitId);
  Api.getJobLocation(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.getLogedTIme = async function (
  jobId,
  visitId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('jobId', jobId);
  data.append('visitId', visitId);
  Api.getLogedTIme(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetTaskDetail = async function (
  organizationId,
  taskId,
  taskSelectedDate,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('taskSelectedDate', taskSelectedDate);
  data.append('taskId', taskId);
  Api.GetTaskDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetRequestDetail = async function (
  organizationId,
  requestId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('requestId', requestId);
  Api.GetRequestDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.SendOTPToOwner = async function (
  orgId,
  userId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('orgId', orgId);
  data.append('userId', userId);
  Api.SendOTPToOwner(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ReSendOTPToOwner = async function (
  otpToken,
  orgId,
  userId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('otpToken', otpToken);
  data.append('orgId', orgId);
  data.append('userId', userId);
  Api.ReSendOTPToOwner(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.VarifiedOTPToOwner = async function (
  otpToken,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('otpToken', otpToken);
  data.append('otp', otp);
  Api.VarifiedOTPToOwner(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.SendOTPToSAdmin = async function (
  orgId,
  userId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('orgId', orgId);
  data.append('userId', userId);
  Api.SendOTPToSAdmin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ReSendOTPToSAdmin = async function (
  otpToken,
  orgId,
  userId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('otpToken', otpToken);
  data.append('orgId', orgId);
  data.append('userId', userId);
  Api.ReSendOTPToSAdmin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.VarifiedOTPToSAdmin = async function (
  otpToken,
  otp,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('otpToken', otpToken);
  data.append('otp', otp);
  Api.VarifiedOTPToSAdmin(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.CreateTestJobs = async function (
  organizationId,
  timeZoneValue,
  year,
  month,
  day,
  file,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('saToken'))
  data.append('organizationId', organizationId);
  data.append('timeZoneValue', timeZoneValue);
  data.append('year', year);
  data.append('month', month);
  data.append('day', day);
  data.append('file', file, file.name)
  Api.CreateTestJobs(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
// GET


AdminModel.prototype.GetEmailConfDetail = async function (
  callBackFunc,
  onError
) {
  let data = '?authToken=' + getCookies('saToken')
  Api.GetEmailConfDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
export default AdminModel
